import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/mensagem-da-direcao.png'
import Cta from '../images/new/banners/mensagem-da-direcao-2.png'

import { 
  section,
  HeaderBanner,
  container,
  content
} from '../components/styles/Base.styles'

const MensagemDaDirecao = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Mensagem da Direção | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]}>
      <div css={container}>
        <h1>Mensagem da Direção</h1>
        <p>Bem-vindos a Kingdom! Sou Alice Simão, diretora geral da escola, e vou contar um pouco para vocês sobre esse espaço do qual tenho muito orgulho de ser uma das idealizadoras.</p>
        <p>A Kingdom nasceu para fazer diferente, somos uma escola focada em fortalecer o potencial existente em cada um. Unimos em nossa proposta pedagógica, métodos exclusivos de ensino e aprendizagem e colocamos nosso aluno como um ser único, que precisa ter um olhar diferenciado para que possa crescer em plenitude.</p>
        <p>Em nossa proposta pedagógica atendemos alunos da Educação Infantil ao Ensino Fundamental I. Nosso projeto combina métodos inovadores com um foco principal no que chamamos de nossa matéria prima: o ser humano que existe dentro de cada aluno.</p>
        <p>Acredito fortemente que a escola não é um meio de ensino-aprendizagem, ela é um espaço cheio de vivências do qual extraímos dali nossas primeiras impressões do que seja a vida e o mundo, além de nossa casa.</p>
        <p>Por isso, nossa escola está tão empenhada em passar para toda equipe e nossos alunos que na Kingdom proporcionamos mais que uma educação de qualidade, somos fonte de vivência para uma grande experiência de vida!</p>
        <p>Além da forte base pedagógica que desenvolvemos, entendemos que o bilíngue é algo fundamental para as demandas dos tempos atuais, por isso nossos alunos têm a chance de diariamente acessar ao Programa de Fluência e Inglês – Beyond, um programa que prepara os alunos desde a parte técnica do idioma, como na parte de desenvoltura e habilidades de conversação.</p>
        <p>Sou muito feliz aqui. E quero dividir essa alegria com mais e mais pessoas.<br /> Será um prazer atender você e sua família.</p>
        <p><span>Venha ser Kingdom e viver essa experiência!</span></p>
        <div>
          <img src={Cta} alt='' style={{ width: '100%' }} />
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default MensagemDaDirecao
